<template>
  <div>
    <v-card
      class="px-2"
    >
      <v-card-title>
        <v-container class="d-flex align-center mb-2">
          <h4>Descrição do atendimento</h4>

          <v-spacer />

          <v-tooltip
            v-if="!waitingToFinish && !isComplaintFinished"
            color="info"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="enableSelect()"
              >
                <v-icon
                  color="info"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>

            <span>Editar</span>
          </v-tooltip>

          <v-tooltip
            top
            color="warning"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="openModalHistory()"
              >
                <v-icon
                  color="warning"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiClipboardText }}
                </v-icon>
              </v-btn>
            </template>

            <span>Histórico</span>
          </v-tooltip>
        </v-container>

        <div
          class="container-divider"
        >
          <div class="custom-divider-text">
            <h4>Dados do Cliente</h4>
          </div>
          <v-divider />
        </div>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="py-0">
            <v-text-field
              v-model="data.protocol"
              label="Número de protocolo"
              outlined
              readonly
              dense
            />
          </v-col>

          <v-col class="py-0">
            <v-text-field
              v-model="data.name_attendance"
              label="Atendente"
              outlined
              readonly
              dense
            />
          </v-col>

          <v-col class="py-0">
            <v-text-field
              v-model="formatCpfNumber"
              label="CPF"
              outlined
              readonly
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="py-0 mb-0"
            cols="5"
          >
            <v-text-field
              v-model="data.name"
              label="Cliente"
              outlined
              readonly
              dense
            />
          </v-col>

          <v-col
            class="py-0 mb-0"
            cols="4"
          >
            <v-text-field
              v-model="data.email"
              label="Email"
              outlined
              readonly
              dense
            />
          </v-col>

          <v-col class="py-0 mb-0">
            <v-text-field
              v-model="formatNumberForTheBrazilianStandard"
              label="Número de telefone"
              outlined
              readonly
              dense
            />
          </v-col>
        </v-row>

        <div class="chips-container">
          <span
            class="container-chips-text"
          >
            Anexos
          </span>

          <div
            class="chips-container-content"
            :style="{'justify-content': isLoadingGetFile ? 'center': 'flex-start'}"
          >
            <v-progress-circular
              v-show="isLoadingGetFile"
              color="warning"
              indeterminate
            />

            <v-chip
              v-for="file in files"
              v-show="computedViewFileContainer"
              :key="file.id"
              size="small"
              color="warning"
              class="chips-content"
              outlined
              dense
              @click="downloadFile(file.id, file.name)"
            >
              <span v-if="file.id === isLoadingFile ? false : true">{{ file.name }}</span>

              <v-progress-circular
                v-else
                :key="file.id"
                :size="25"
                color="white"
                indeterminate
              />
            </v-chip>

            <h4
              v-show="!computedViewFileContainer && !isLoadingGetFile"
              class="font-100"
            >
              - Nenhum anexo
            </h4>
          </div>
        </div>

        <v-row
          class="mt-4 px-4"
          align="start"
          justify="end"
          dense
        >
          <v-icon>
            {{ icons.mdiPaperclip }}
          </v-icon>

          <p
            style="text-decoration: underline; cursor: pointer;"
            @click="computedStatusIsOpen ? openFileModal() : null"
          >
            Inserir mais arquivos
          </p>
        </v-row>

        <div
          class="container-divider"
        >
          <div class="custom-divider-text">
            <h4>Dados da Reclamação</h4>
          </div>
          <v-divider />
        </div>

        <v-row class="mt-4">
          <v-col class="pb-0 mb-0">
            <v-text-field
              v-model="data.fantasy_name"
              label="Filial"
              outlined
              readonly
              dense
            />
          </v-col>

          <v-col class="pb-0 mb-0">
            <v-text-field
              v-model="data.name_reasons_complaints"
              label="Motivo"
              outlined
              readonly
              dense
            />
          </v-col>

          <v-col class="pb-0 mb-0">
            <v-text-field
              v-model="data.name_sectors_complaints"
              label="Setor"
              outlined
              readonly
              dense
            />
          </v-col>
        </v-row>

        <v-form ref="formLeader">
          <div style="width: 50%;">
            <v-autocomplete
              v-model="selectedLeader"
              :items="leaders"
              item-text="name"
              item-value="id"
              class="mt-4"
              :rules="[selectLeaderRule]"
              label="Gerente/Líder do Colaborador"
              :disabled="disableSelectLeader"
              return-object
              outlined
              dense
            />
          </div>
        </v-form>

        <div
          class="container-divider"
        >
          <div class="custom-divider-text">
            <h4>Atribuir Responsável</h4>
          </div>
          <v-divider />
        </div>

        <v-form ref="formEmployee">
          <div style="width: 40%; margin-top: 30px;">
            <v-autocomplete
              ref="selectEmployee"
              v-model="selectedEmployee"
              :items="employees"
              item-text="name"
              item-value="id"
              label="Colaborador"
              :rules="[selectEmployeeRule]"
              :disabled="disableSelectEmployee"
              return-object
              outlined
              dense
            />
          </div>
        </v-form>

        <div class="d-flex justify-center mt-4">
          <v-btn
            v-show="computedStatusIsOpen && !waitingToFinish"
            color="info"
            @click="replyToModal()"
          >
            <v-img :src="icons.arrowLeft" />
            <span class="ml-2">Responder</span>
          </v-btn>

          <v-btn
            v-show="waitingToFinish && !isComplaintFinished && isCallCenterLeader"
            color="success"
            @click="finishAttendance()"
          >
            <span v-if="!isLoadingAttendanceFinish">Finalizar</span>
            <v-progress-circular
              v-else
              color="white"
              class="mx-6"
              indeterminate
            />
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="showFileModal"
      width="500px"
    >
      <FileModal
        :key="data.id"
        :data="data"
        @getImageList="getImageList"
        @close="showFileModal = false"
      ></FileModal>
    </v-dialog>

    <v-dialog
      v-model="showModalHistory"
      width="700px"
    >
      <HistoryComplaint
        :key="data.id"
        :data="data"
        :form-data="formData"
        @updatedTable="updatedTable(), closeModal()"
        @close="showModalHistory = false"
      ></HistoryComplaint>
    </v-dialog>
  </div>
</template>

<script>
import arrowLeft from '@/assets/images/svg/arrow-left.svg'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiClipboardText, mdiPaperclip, mdiPencil,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import FileModal from './FileModal.vue'
import HistoryComplaint from './HistoryComplaint.vue'

export default {
  components: {
    FileModal,
    HistoryComplaint,
  },

  mixins: [formatters, messages],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      files: [],
      leaders: [],
      employees: [],

      formData: {},
      selectedLeaderId: '',
      selectedEmployeeId: '',

      showFileModal: false,
      showModalHistory: false,

      isLoading: false,
      isLoadingFile: false,
      isViewingFile: false,
      isLoadingGetFile: false,
      disableSelectLeader: true,
      disableSelectEmployee: true,
      isLoadingAttendanceFinish: false,

      icons: {
        mdiClipboardText, mdiPencil, mdiPaperclip, arrowLeft,
      },
    }
  },

  computed: {
    computedViewFileContainer() {
      const isFileLoading = !this.isLoadingGetFile
      const isFileViewing = this.isViewingFile

      return isFileLoading && isFileViewing
    },

    formatNumberForTheBrazilianStandard() {
      const cellphone = this.data?.cellphone

      return this.formattedPhoneNumberToBrazil(cellphone) ?? ''
    },

    formatCpfNumber() {
      const cpfNumber = this.data?.cpf

      return this.formattedCpfNumber(cpfNumber) ?? ''
    },

    selectedEmployee: {
      get() {
        const employeeId = this.data?.employee_id
        const lastIndex = this.employees.length - 1

        if (employeeId) {
          return this.employees.find(employee => employee.id === employeeId)
        }

        return this.employees[lastIndex]
      },

      set(value) {
        this.selectedEmployeeId = value?.id
      },
    },

    selectedLeader: {
      get() {
        const { data, leaders } = this
        const leaderId = data?.id_responsible
        const lastIndex = leaders.length - 1
        const selectedLeader = leaders.find(leader => leader.id === leaderId)

        if (leaderId) {
          return selectedLeader
        }

        return this.leaders[lastIndex]
      },

      set(value) {
        this.selectedLeaderId = value?.id
      },
    },

    /**
    * @returns {string|boolean}
    */
    selectLeaderRule() {
      const { selectedLeader, selectedLeaderId, data } = this
      const leaderId = data?.id_responsible

      if (leaderId || selectedLeaderId) return true

      if (!selectedLeader.id || !leaderId) return '* Campo obrigatório'

      return true
    },

    /**
    * @returns {string|boolean}
    */
    selectEmployeeRule() {
      const {
        data, isCallCenterLeader, selectedEmployeeId,
      } = this

      const employeeId = data?.employee_id

      if (employeeId || selectedEmployeeId) return true

      if (!employeeId && !isCallCenterLeader) return '* Campo obrigatório'

      return true
    },

    computedStatusIsOpen() {
      const { data } = this
      const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
      const hierarchicalProfile = userProfile?.hierarchical_profile
      const statusList = ['AGUARDANDO CENTRAL', 'AGUARDANDO LÍDER', 'AGUARDANDO GR', 'AGUARDANDO RD']
      const isOpen = statusList.includes(data?.status) ?? false

      if (hierarchicalProfile === 'nivel_1') return false

      return isOpen
    },

    isCallCenterLeader() {
      const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
      const sector = userProfile?.occupation_sector?.sector?.name
      const isSupervisor = userProfile?.supervisor
      const userIsCallCenterLeader = isSupervisor && sector === 'CENTRAL DE RELACIONAMENTO'

      return userIsCallCenterLeader
    },

    userIsLeader() {
      const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
      const hierarchicalProfile = userProfile?.hierarchical_profile

      return hierarchicalProfile !== 'nivel_1'
    },

    isComplaintFinished() {
      const isFinished = this.data?.status === 'FINALIZADO'

      return isFinished
    },

    waitingDirectorResponse() {
      return this.data?.status === 'AGUARDANDO RD'
    },

    waitingToFinish() {
      return this.data?.status === 'AGUARDANDO ENCERRAMENTO'
    },
  },

  created() {
    this.leaders = localStorageSlim.get('leaderList', { decrypt: true })
    this.employees = localStorageSlim.get('listEmployees', { decrypt: true })
    this.leaders.push({ id: false, name: '- Não Atribuído' })
    this.employees.push({ id: false, name: '- Não Atribuído' })

    this.getImageList()
  },

  methods: {
    replyToModal() {
      const { $refs, selectedLeaderId, selectedEmployeeId } = this
      const formLeaderIsValid = $refs.formLeader.validate()
      const formEmployeeIsValid = $refs.formEmployee.validate()

      if (!formLeaderIsValid) {
        this.showMessage({ title: 'Atenção!', text: 'Selecione o líder' })

        return
      }

      if (!formEmployeeIsValid) {
        this.showMessage({ title: 'Atenção!', text: 'Selecione o colaborador' })

        return
      }

      this.formData = { selectedLeaderId, selectedEmployeeId }
      this.showModalHistory = true
    },

    async finishAttendance() {
      const { data } = this

      this.isLoadingAttendanceFinish = true

      const body = {
        users_id: data.users_id,
        status: 'FINALIZADO',
        rics_id: data?.id,
        observation: 'Atendimento Finalizado!',
      }

      await axiosIns
        .post('api/v1/sgq/responsible/store', body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta foi enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isLoadingAttendanceFinish = false
          this.closeModal()
        })
    },

    async getImageList() {
      this.isLoadingGetFile = true
      const { id } = this.data

      await axiosIns
        .get(`api/v1/sgq/ric/edit/${id}`)
        .then(response => {
          this.files = response.data.data?.attachment

          if (this.files?.length > 0) {
            this.isViewingFile = true
          }
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => { this.isLoadingGetFile = false })
    },

    /**
     * Downloads a file given its ID and name.
     * @param {string} fileId - The ID of the file to download.
     * @param {string} fileName - The name of the file to download.
     */
    async downloadFile(fileId, fileName) {
      this.isLoadingFile = fileId

      const body = { id: fileId }

      await axiosIns
        .post('api/v1/sgq/attachment/view_files', body, { responseType: 'arraybuffer' })
        .then(response => {
          const contentType = response.headers['content-type']
          const file = new File([response.data], fileName, {
            type: contentType,
          })

          saveAs(file)
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isLoadingFile = ''
        })
    },

    enableSelect() {
      if (this.isCallCenterLeader) {
        this.disableSelectLeader = !this.disableSelectLeader
      }

      if (this.userIsLeader && !this.isCallCenterLeader) {
        this.disableSelectEmployee = !this.disableSelectEmployee
      }
    },

    openFileModal() {
      this.showFileModal = true
    },

    openModalHistory() {
      this.formData = false
      this.showModalHistory = true
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()
    },
  },
}
</script>

<style scoped>
.chips-container {
  display: flex;
  flex-direction: column;
}

.chips-container-content {
  width: 100%;
  height: auto;
  min-height: 60px ;
  display: flex;
  padding: 12px;
  font-size: 10px;
  gap: 6px;

  border-radius: 5px;
  border: 0.0111rem solid #4e4a67;
}

.container-chips-text {
  display: relative;
  text-align: center;
  margin-bottom: -10px;
  margin-left: 15px;
  background: #312D4B;
  z-index: 1;
  width: 6%;
}

.chips-content {
  margin-right: 0.4444rem;
}

.container-divider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 18px;
  /* margin: 20px 0px; */
}

.custom-divider-text {
  display: relative;
  width: 20%;
  text-align: center;
  background: #312D4B;
  font-size: 14px;
  margin-left: 40px;
  margin-bottom: -12px;
  z-index: 1;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #d9d9d9;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
