<template>
  <v-card>
    <v-card-title class="justify-center">
      Registar reclamação
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-col>
          <v-row class="mt-1">
            <v-text-field
              v-model="name"
              class="mr-8"
              label="Nome"
              dense
              outlined
              :rules="[rules.required]"
            />

            <v-text-field
              v-model="cpf"
              label="CPF"
              outlined
              dense
              :rules="[rules.required]"
            />
          </v-row>

          <v-row>
            <v-text-field
              v-model="email"
              class="mr-8"
              label="Email"
              outlined
              dense
              type="email"
            />

            <v-text-field
              v-model="cellNumber"
              label="Número de telefone"
              outlined
              dense
              :rules="[rules.required]"
            />

            <v-autocomplete
              v-model="selectedComplaintReasonId"
              :items="listOfReasonComplaint"
              :rules="[rules.required]"
              class="mr-8"
              item-text="name"
              item-value="id"
              label="Motivo"
              outlined
              dense
            />

            <v-autocomplete
              v-model="selectedSectorId"
              :items="sectors"
              item-text="name"
              item-value="id"
              label="Setor"
              outlined
              dense
            />
          </v-row>
        </v-col>

        <v-file-input
          ref="fileInput"
          v-model="files"
          :append-icon="icons.mdiPaperclip"
          prepend-icon=""
          label="Anexos"
          class="mb-0"
          outlined
          multiple
          chips
          dense
        />
        <p>
          * O tamanho do arquivo não pode exceder o limite máximo de 10MB.
        </p>
      </v-form>

      <vue-editor v-model="description" />

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          @click="closeModal(), resetForm()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiCamera, mdiPaperclip } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },

  mixins: [formatters, messages, utilsMixin],

  data() {
    return {
      cpf: '',
      name: '',
      email: '',
      cellNumber: '',
      description: '',
      selectedSectorId: '',

      files: [],
      sectors: [],
      employees: [],
      listOfReasonComplaint: [],
      selectedComplaintReasonId: '',

      isLoading: false,
      fileSizeError: false,

      icons: {
        mdiCamera,
        mdiPaperclip,
      },
    }
  },

  created() {
    this.employees = localStorageSlim.get('listEmployees', { decrypt: true })

    this.getListOfReasonComplaint()
    this.getListOfSectors()
  },

  methods: {
    async getListOfSectors() {
      await axiosIns
        .get('api/v1/records/sector/index')
        .then(response => {
          this.sectors = response.data.data
        })
        .catch(error => this.showErrorMessage(error))
    },

    async getListOfReasonComplaint() {
      await axiosIns
        .get('api/v1/sgq/reason_complaint/index')
        .then(response => {
          this.listOfReasonComplaint = response.data.data
        })
        .catch(error => this.showErrorMessage(error))
    },

    async sendModalData() {
      const formIsValid = this.$refs.form.validate()

      const {
        name,
        cpf,
        email,
        cellNumber,
        description,
        selectedSectorId,
        selectedComplaintReasonId,
      } = this

      const body = {
        cpf,
        email,
        name: name?.toUpperCase(),
        status: 'AGUARDANDO CENTRAL',
        cellphone: cellNumber,
        complaint: description,
        sectors_id: selectedSectorId,
        reasons_complaints_id: selectedComplaintReasonId,
      }

      if (!formIsValid) {
        this.showMessage({ title: 'Campos vazios!', text: 'Verifique se todos os campos foram preenchidos' })

        return
      }

      this.isLoading = true

      await axiosIns
        .post('/api/v1/sgq/ric/store', body)
        .then(response => {
          const { data } = response.data

          if (this.files.length === 0) {
            this.showMessage({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Sua resposta enviada com sucesso!',
            })
          }

          if (this.files.length > 0) {
            this.sendFiles('api/v1/sgq/attachment/submit_files', this.files, { id: data?.id })
          }

          this.updatedTable()
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isLoading = false
          this.resetForm()
          this.closeModal()
        })
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()

      this.description = ''
    },
  },
}
</script>
