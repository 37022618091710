<template>
  <v-skeleton-loader
    v-if="isSkeleton"
    type="article, actions"
  />
  <div v-else>
    <v-card>
      <v-card-title class="justify-center">
        Histórico da Reclamação
      </v-card-title>

      <v-card-text>
        <v-timeline
          class="timeline-custom-dense timeline-custom-dots mt-4"
          dense
        >
          <v-timeline-item
            color="success"
            small
          >
            <div class="container">
              <div class="container-image">
                <v-avatar>
                  <v-img
                    v-if="!profilePhoto"
                    src="@/assets/images/avatars/avatar-none.png"
                  />
                  <v-img
                    v-else
                    :src="profilePhoto"
                  />
                </v-avatar>
              </div>
              <div class="container-content">
                <div>
                  <h3>{{ historyData[0].name_ric ? historyData[0].name_ric : '' }}</h3>
                </div>

                <div>
                  <span>{{ historyData[0].created_at ? dateFormat(historyData[0].created_at) : '' }}</span>
                </div>
                <br />
              </div>
            </div>
            <div class="container-description">
              {{ historyData[0].complaint ? removeHtmlInString(historyData[0].complaint) : '' }}
            </div>
          </v-timeline-item>

          <v-timeline-item
            v-for="item in historyData"
            v-show="!isStatusRegistered"
            :key="item.created"
            color="success"
            small
          >
            <div class="container">
              <div class="container-image">
                <v-avatar>
                  <v-img
                    v-if="!profilePhoto"
                    src="@/assets/images/avatars/avatar-none.png"
                  />
                  <v-img
                    v-else
                    :src="profilePhoto"
                  />
                </v-avatar>
              </div>
              <div class="container-content">
                <div>
                  <h3>{{ item.name_create ? item.name_create : '' }}</h3>
                </div>

                <div>
                  <span>{{ item.created ? dateFormat(item.created) : '' }}</span>
                </div>
                <br />
              </div>
            </div>
            <div class="container-description">
              {{ item.observation ? removeHtmlInString(item.observation) : '' }}
            </div>
          </v-timeline-item>

          <v-timeline-item
            v-show="!isComplaintFinished"
            class="mb-2"
            color="info"
            small
          >
            <div class="container">
              <div class="container-image">
                <v-avatar>
                  <v-img
                    v-if="!profilePhoto"
                    src="@/assets/images/avatars/avatar-none.png"
                  />
                  <v-img
                    v-else
                    :src="profilePhoto"
                  />
                </v-avatar>
              </div>
              <div class="container-content">
                <div>
                  <h3>{{ getLeaderDescriptionPendingResponse }}</h3>
                </div>

                <div>
                  <span>...</span>
                </div>
                <br />
              </div>
            </div>
            <div class="container-description">
              ...
            </div>
          </v-timeline-item>
        </v-timeline>

        <div
          v-show="showContainerDescription"
          class="pt-2 px-6"
        >
          <v-form
            ref="form"
            @submit.prevent
          >
            <v-textarea
              v-model="description"
              label="Descrição"
              :rules="inputRules"
              auto-grow
              outlined
            />
          </v-form>
        </div>

        <div v-show="showContainerDescription && (!waitingDirectorResponse && !waitingRegionalManager)">
          <div class="d-flex justify-center mt-2">
            <v-btn
              v-show="formData"
              color="success"
              @click="sendModalData()"
            >
              <div
                v-if="!isLoading"
                class="container-button"
              >
                <span class="text-sm">
                  Enviar
                </span>
                <v-img
                  :src="icons.arrowRight"
                  class="ml-2"
                />
              </div>

              <v-progress-circular
                v-else
                color="white"
                class="mx-6"
                indeterminate
              />
            </v-btn>
          </div>
        </div>

        <div
          v-if="showDirectorContainer"
          class="d-flex justify-space-between px-6 mt-2"
        >
          <v-btn
            color="info"
            @click="sendModalData({backToLeader: true})"
          >
            <div
              v-if="!isLoadingBackToLeader"
              class="container-button"
            >
              <span>Retornar Líder</span>
              <v-img
                :src="icons.arrowLeftDown"
                class="ml-2"
              />
            </div>

            <v-progress-circular
              v-else
              color="white"
              class="mx-6"
              indeterminate
            />
          </v-btn>

          <v-btn
            v-if="waitingRegionalManager"
            color="success"
            @click="sendModalData({sendToDirector: true})"
          >
            <div
              v-if="!isLoadingValidation"
              class="container-button"
            >
              <v-img
                :src="icons.arrowRight"
              />
              <span class="ml-2">Enviar para RD</span>
            </div>

            <v-progress-circular
              v-else
              color="white"
              class="mx-6"
              indeterminate
            />
          </v-btn>

          <v-btn
            v-else
            color="success"
            @click="sendModalData({sendToCallCenter: true})"
          >
            <div
              v-if="!isLoadingValidation"
              class="container-button"
            >
              <v-img
                :src="icons.like"
              />
              <span class="ml-2">Validar</span>
            </div>

            <v-progress-circular
              v-else
              color="white"
              class="mx-6"
              indeterminate
            />
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import arrowLeftDown from '@/assets/images/svg/arrow-left-down.svg'
import arrowRight from '@/assets/images/svg/arrow-right.svg'
import like from '@/assets/images/svg/like.svg'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import localStorageSlim from 'localstorage-slim'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: Object,
      required: true,
    },

    formData: {
      type: [Object, Boolean],
      required: true,
    },
  },

  data() {
    return {
      description: '',
      profilePhoto: '',
      historyData: [],

      isLoading: false,
      isSkeleton: false,
      isLoadingValidation: false,
      isLoadingBackToLeader: false,

      icons: { arrowRight, arrowLeftDown, like },
    }
  },

  computed: {
    inputRules() {
      if (!this.waitingDirectorResponse) {
        return [
          v => !!v || 'Campo obrigatório',
        ]
      }

      return []
    },

    getLeaderDescriptionPendingResponse() {
      const historyDataLength = this.historyData.length
      const lastItem = this.historyData[historyDataLength - 1]

      return lastItem?.users_responsible_name ?? ''
    },

    isComplaintFinished() {
      const isFinished = this.data?.status === 'FINALIZADO'

      return isFinished
    },

    isStatusRegistered() {
      const status = this.data?.status
      const isRegistered = status === 'AGUARDANDO CENTRAL'

      return isRegistered
    },

    userIsLeader() {
      const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
      const hierarchicalProfile = userProfile?.hierarchical_profile

      return hierarchicalProfile !== 'nivel_1'
    },

    showContainerDescription() {
      const { formData, userIsLeader, isComplaintFinished } = this

      return formData && !isComplaintFinished && userIsLeader
    },

    waitingDirectorResponse() {
      const { data, formData } = this
      const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
      const status = data?.status === 'AGUARDANDO RD'
      const isLevelThree = userProfile?.hierarchical_profile === 'nivel_3'

      return !!(status && isLevelThree && formData)
    },

    waitingRegionalManager() {
      const { data, formData } = this
      const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
      const occupation = userProfile?.occupation_sector?.occupation?.name
      const status = data?.status === 'AGUARDANDO GR'
      const isRegionalManager = occupation === 'GERENTE REGIONAL'

      return !!(status && isRegionalManager && formData)
    },

    showDirectorContainer() {
      return this.waitingDirectorResponse || this.waitingRegionalManager
    },
  },

  created() {
    this.getHistoricalData()
  },

  methods: {
    async getHistoricalData() {
      this.isSkeleton = true
      const { id } = this.data

      const body = {
        rics_id: id,
      }

      await axiosIns
        .post('api/v1/sgq/responsible/timeline_responsible', body)
        .then(response => {
          this.historyData = response.data.data
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isSkeleton = false
        })
    },

    /**
     * Sends modal data to the server.
     *
     * @param {Object} options - Options for sending the data.
     * @param {boolean} options.backToLeader - Flag indicating whether to send the data back to the leader.
     * @param {boolean} options.sendToCallCenter - Flag indicating whether to send the data to the call center.
     * @return {Promise<void>} A promise that resolves when the data is sent successfully.
     */
    async sendModalData({ backToLeader = false, sendToCallCenter = false, sendToDirector = false } = {}) {
      const isValid = this.$refs.form.validate()

      const {
        description, data, formData,
      } = this

      if (!isValid) {
        this.showMessage({ title: 'Descrição vazia!', text: 'por favor insira um texto' })

        return
      }

      this.isLoading = true

      const body = {
        status: this.getStatusFromBody(),
        rics_id: data.id,
        users_id: formData.selectedLeaderId,
        observation: description,
        employee_id: formData.selectedEmployeeId || data.employee_id,
      }

      if (sendToDirector) {
        body.rd = true
        body.status = 'AGUARDANDO RD'
        this.isLoadingValidation = true
      }

      if (backToLeader) {
        body.users_id = data.regional_leader_id
        body.status = 'AGUARDANDO LÍDER'
        this.isLoadingBackToLeader = true
      }

      if (sendToCallCenter) {
        body.users_id = data.users_leader_id
        body.status = 'AGUARDANDO ENCERRAMENTO'
        this.isLoadingValidation = true
      }

      await axiosIns
        .post('api/v1/sgq/responsible/store', body)
        .then(() => {
          this.showMessage({ icon: 'success', title: 'Sucesso!', text: 'Enviado com sucesso!' })
          this.updatedTable()
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isLoading = false
          this.isLoadingValidation = false
          this.isLoadingBackToLeader = false
          this.resetForm()
        })
    },

    getStatusFromBody() {
      const { name_responsible: responsible, region, status } = this.data

      if (region === 'IMPERATRIZ' && status === 'AGUARDANDO LÍDER') return 'AGUARDANDO RD'

      if (responsible) return 'AGUARDANDO GR'

      return 'AGUARDANDO LÍDER'
    },

    resetForm() {
      this.$refs.form.reset()
      this.closeModal()
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
}

.container-image {
  margin-right: 20px;
}

.container-description {
  margin-left: 20px;
  margin-bottom: 20px;
}

.container-button {
  display: flex;
  justify-content:center;
  align-items: center;
  gap: 5px;
}

/* Retira o incrementador padrão dos inputs do tipo "number"*/
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
