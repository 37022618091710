<template>
  <div>
    <v-card class="mb-6">
      <v-card-text class="container-content">
        <span class="mr-4">Prazos de resposta do líder:</span>

        <v-spacer />

        <div class="container-title">
          <div class="container-ball-white"></div>
          <span class="ml-2 mr-4">0 horas</span>
        </div>

        <div class="container-title">
          <div class="container-ball-warning"></div>
          <span class="ml-2 mr-4">12 horas</span>
        </div>

        <div class="container-title">
          <div class="container-ball-orange"></div>
          <span class="ml-2 mr-4">24 horas</span>
        </div>

        <div class="container-title">
          <div class="container-ball-error"></div>
          <span class="ml-2 mr-4">48 horas</span>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mb-6">
      <v-card-text class="container-content">
        <span class="mr-4">Status da Reclamação:</span>

        <v-spacer />

        <div class="container-title">
          <div class="container-ball-emerald-green"></div>
          <span class="ml-2 mr-4">Aguardando Central</span>
        </div>

        <div class="container-title">
          <div class="container-ball-white"></div>
          <span class="ml-2 mr-4">Aguardando Líder</span>
        </div>

        <div class="container-title">
          <div class="container-ball-yellowGold"></div>
          <span class="ml-2 mr-4">Aguardando GR</span>
        </div>

        <div class="container-title">
          <div class="container-ball-info"></div>
          <span class="ml-2 mr-4">Aguardando RD</span>
        </div>

        <div class="container-title">
          <div class="container-ball-purple"></div>
          <span class="ml-2 mr-4">Aguardando Encerramento</span>
        </div>

        <div class="container-title">
          <div class="container-ball-success"></div>
          <span class="ml-2 mr-4">Finalizado</span>
        </div>

        <v-space></v-space>
      </v-card-text>
    </v-card>

    <v-card class="mb-6">
      <v-card-title class="">
        <span> Atendimentos</span>

        <v-spacer />

        <v-btn
          class="mr-2"
          color="warning"
          outlined
          @click="exportCsv"
        >
          <span class="ml-2">Exportar</span>
          <v-img
            class="ml-2"
            :src="require('@/assets/download-icon.svg')"
          />
        </v-btn>

        <v-btn
          v-show="showButtonFilterPermission"
          outlined
          :color="filterButtonColor"
          @click="handleShowFilterRics"
        >
          <div
            class=" d-flex align-center justify-center"
            style="gap: 10px;"
          >
            <v-icon>{{ icons.mdiFilterVariant }}</v-icon>
            <span>Filtrar</span>
          </div>
        </v-btn>
        <v-btn
          v-if="isFiltered"
          outlined
          class="ml-3"
          color="error"
          @click="clearBtn"
        >
          <div
            class="d-flex align-end justify-center pb-1 "
            style="gap: 10px;"
          >
            <v-icon size="22">
              {{ icons.mdiFilterVariantRemove }}
            </v-icon>
            <span class="button-text-logs">LIMPAR</span>
          </div>
        </v-btn>

        <v-btn
          v-show="IsACallCenterAttendance"
          color="info"
          class="ml-4"
          outlined
          @click="openModal('createAttendance')"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="itemsTable"
        :loading="isLoading"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <NoDataInterface />
        </template>

        <template v-slot:item.status="{ item }">
          <v-alert
            class="badge"
            :color="getStatusColor(item)"
            text
          >
            {{ item.status ? item.status : '-' }}
          </v-alert>
        </template>

        <template v-slot:item.fantasy_name="{ item }">
          <v-alert
            class="badge"
            :color="getCompanyColor(item.fantasy_name)"
            text
          >
            {{ item.fantasy_name ? item.fantasy_name : '-' }}
          </v-alert>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.created_at ? dateFormat(item.created_at) : '-' }}
        </template>

        <template v-slot:item.return_date="{ item }">
          {{ item.return_date ? dateFormat(item.return_date) : '-' }}
        </template>
        <template v-slot:item.name_responsible="{ item }">
          {{ item.name_responsible ? item.name_responsible : '-' }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon
            medium
            class="me-2"
            color="warning"
            @click="openModal('details', item)"
          >
            {{ icons.mdiEye }}
          </v-icon>

          <v-icon
            medium
            color="info"
            class="me-2"
            @click="openModal('history', item)"
          >
            {{ icons.mdiClipboardText }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showModalAttendance"
      width="700px"
    >
      <ModalAttendance
        @updatedTable="updatedTable()"
        @close="showModalAttendance = false"
      ></ModalAttendance>
    </v-dialog>

    <v-dialog
      v-model="showAttendanceDetails"
      width="1000px"
    >
      <AttendanceDetails
        :key="detailsAttendance.id"
        :data="detailsAttendance"
        :user-permissions="userPermissions"
        @updatedTable="updatedTable()"
        @close="showAttendanceDetails = false"
      ></AttendanceDetails>
    </v-dialog>

    <v-dialog
      v-model="showComplaintHistory"
      width="700px"
    >
      <HistoryComplaint
        :key="detailsAttendance.randomId"
        :form-data="false"
        :data="detailsAttendance"
        :user-profile="userProfile"
        @updatedTable="updatedTable()"
        @close="showComplaintHistory = false"
      ></HistoryComplaint>
    </v-dialog>
    <v-dialog
      v-model="ShowFilterRics"
      width="512"
      persistent
    >
      <ModalFilterRics
        ref="ModalFilterRics"
        @close="ShowFilterRics = false"
        @isFiltered="$event => isFiltered = $event"
        @filteredDataBody="$event => filteredDataBody = $event"
        @getFilter="getItemsTable"
        @itemsTableIndex="getItemsTable"
      >
      </ModalFilterRics>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/plugins/axios'
// eslint-disable-next-line import/no-cycle
import { getVuetify } from '@/@core/utils'
import NoDataInterface from '@/components/charts/NoDataInterface.vue'
// eslint-disable-next-line import/no-cycle
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiClipboardText, mdiEye, mdiFilterPlusOutline, mdiFilterRemoveOutline,
  mdiFilterVariant,
  mdiFilterVariantRemove,
  mdiPlaylistPlus,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import AttendanceDetails from './AttendanceDetails.vue'
import HistoryComplaint from './HistoryComplaint.vue'
import ModalAttendance from './ModalAttendance.vue'
import ModalFilterRics from './ModalFilterRics.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    ModalAttendance,
    HistoryComplaint,
    AttendanceDetails,
    NoDataInterface,
    ModalFilterRics,
  },

  mixins: [formatters, messages],

  data() {
    return {
      showButtonFilterPermission: '',
      ShowFilterRics: false,
      isFiltered: false,
      filterData: '',
      userProfile: {},
      filteredDataBody: [],
      userPermissions: [],
      detailsAttendance: {},

      headers: [
        {
          text: 'PROTOCOLO',
          value: 'protocol',
          sortable: false,
          align: 'left',
        },
        {
          text: 'DATA DO REGISTRO',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'LOJA',
          value: 'fantasy_name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'SETOR',
          value: 'sector',
          sortable: true,
          align: 'center',
        },
        {
          text: 'GESTOR',
          value: 'name_responsible',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA DE DEVOLUÇÃO',
          value: 'return_date',
          sortable: false,
          align: 'center',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: true,
          align: 'center',
        },
        {
          text: 'DETALHES',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      listOfFilteredItems: [],

      isLoading: false,
      showModalAttendance: false,
      showComplaintHistory: false,
      showAttendanceDetails: false,
      IsACallCenterAttendance: false,

      icons: {
        mdiFilterVariantRemove,
        mdiEye,
        mdiPlaylistPlus,
        mdiClipboardText,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
        mdiFilterVariant,
      },
    }
  },

  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
    filterButtonColor() {
      if (!this.isDark) {
        return this.isFiltered ? 'success' : 'black'
      }

      return this.isFiltered ? 'success' : 'white'
    },
  },

  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.userPermissions = localStorageSlim.get('userPermissions', { decrypt: true })
    this.getItemsTable()
    this.verifyUserIsLeaderCallCenter()

    const isAdm = this.userProfile.profile.profile === 'Administrador'
    const isDirector = this.userProfile.profile.profile === 'Diretor'
    const isRelationshipCenter = this.userProfile.profile.profile === 'Central de Relacionamento' && this.userProfile.company.city === 'IMPERATRIZ'

    const permissionBtnFilter = isAdm || isDirector || isRelationshipCenter

    this.showButtonFilterPermission = permissionBtnFilter
  },

  methods: {
    async exportCsv(item) {
      const file = await this.getCsv(item)
      if (file) {
        saveAs(file)
      }
    },

    async getCsv() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'PROTOCOLO', key: 'region' },
        { header: 'DATA DO REGISTRO', key: 'fantasy_name' },
        { header: 'LOJA', key: 'name' },
        { header: 'SETOR', key: 'cpf' },
        { header: 'GESTOR', key: 'level' },
        { header: 'DATA DE DEVOLUÇÃO', key: 'goal' },
        { header: 'STATUS', key: 'sales' },
        { header: 'CPF', key: 'cpf' },
        { header: 'MOTIVO', key: 'name_reasons_complaints' },
      ]

      this.itemsTable.map(itemTable => {
        worksheet.addRow([
          itemTable.protocol,
          itemTable.created_at,
          itemTable.fantasy_name,
          itemTable.sector,
          itemTable.name_responsible,
          itemTable.return_date,
          itemTable.status,
          itemTable.cpf,
          itemTable.name_reasons_complaints,
        ])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'Atendimentos', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })

      return blob
    },

    clearBtn() {
      this.$refs.ModalFilterRics?.clearModal()
      this.filteredDataBody = []
      this.getItemsTable()
      this.isFiltered = false
    },
    handleShowFilterRics() {
      this.ShowFilterRics = true
    },
    async getItemsTable() {
      this.isLoading = true

      await axiosIns
        .post('/api/v1/sgq/ric/filter', this.filteredDataBody)
        .then(response => {
          const { data } = response.data

          this.itemsTable = data
          console.log(this.itemsTable)
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isLoading = false
        })
    },

    /**
     * Returns the color associated with a given status string.

     * @param {Object} item - An object containing a `status` string and `time_difference` num
     * @param {number} item.time_difference - The number of hours since the leader standby was ope
     * @param {string} item.status - The color associated with the given `status` str
     * @return {string} The color associated with the given `status` string or an empty string if not fo
     */
    getStatusColor(item = { status: '', time_difference: 0 }) {
      const { status, time_difference: openTime } = item

      const statusColorMap = {
        'AGUARDANDO RD': 'info',
        'AGUARDANDO CENTRAL': 'emeraldGreen',
        'AGUARDANDO ENCERRAMENTO': 'purple',
        'AGUARDANDO GR': 'yellowGold',
        'AGUARDANDO LÍDER': this.getStatusColorForLeaderStandby(openTime),
        FINALIZADO: 'success',
      }

      return statusColorMap[status] ?? ''
    },

    /**
     * Get the company color based on the company name.
     *
     * @param {string} companyName - The name of the company.
     * @return {string} The color associated with the company name. Returns an empty string if the company name is not found.
     */
    getCompanyColor(companyName) {
      const colorMap = {
        'MOTOCA IMPERATRIZ': 'companyYellow',
        'MOTOCA AÇAILÂNDIA': 'companyPurple',
        'MOTOCA GRAJAÚ': 'companyGreen',
      }

      return colorMap[companyName] ?? ''
    },

    /**
     * Returns a color status based on the provided open time.
     *
     * @param {number} openTime - The number of hours since the leader standby was opened.
     * @return {string} A string representing the color status. Possible values: "white", "warning", "orange", "error", or an empty string.
     */
    getStatusColorForLeaderStandby(openTime) {
      if (openTime > 0 && openTime < 12) {
        return 'white'
      }

      if (openTime >= 12 && openTime < 24) {
        return 'warning'
      }

      if (openTime >= 24 && openTime < 48) {
        return 'orange'
      }

      if (openTime >= 48) {
        return 'error'
      }

      return ''
    },

    verifyUserIsLeaderCallCenter() {
      // const regexMarketing = /COORD DE MARKETING/i
      // const regexRelationshipCenter = /central\s+de\s+relacionamento/i
      // const { occupation, sector } = this.userProfile.occupation_sector ?? { sector: { name: '' }, occupation: { name: '' } }
      // const isRelationshipCenter = regexRelationshipCenter.test(sector?.name)
      // const isMarketing = regexMarketing.test(occupation.name)

      this.IsACallCenterAttendance = this.userProfile.profile.profile === 'Central de Relacionamento'
    },

    updatedTable() {
      this.getItemsTable()
    },

    /**
     * Opens a modal based on the type parameter and sets the detailsAttendance property.
     *
     * @param {string} type - The type of modal to open.
     * @param {Object} details - Optional details to pass to the modal.
     */
    openModal(type, details = {}) {
      const modal = {
        createAttendance: () => { this.showModalAttendance = true },
        history: () => {
          this.showComplaintHistory = true
          this.detailsAttendance = details
          this.detailsAttendance.randomId = Math.random()
        },
        details: () => {
          this.showAttendanceDetails = true
          this.detailsAttendance = details
        },
      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error(`Invalid modal type: ${type}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.badge {
  margin-top: 16px;
  font-size: 13px;
  height: 40px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  * {
    font-size: small;
  }
}

.container-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.container-ball-orange {
  background: #ff821a;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-warning {
  background: #ffb400;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-info {
  background: #16b1ff;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-yellowGold {
  background: #FFE500;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-purple {
  background: #9155fd;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.button-text-logs{
  font-size: 14px;
  font-weight: 600;
}

.container-ball-success {
  background: #56ca00;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-error {
  background: #ff4c51;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-white {
  background: #e8f0ff;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-emerald-green {
  background: #22CB84;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}
</style>
