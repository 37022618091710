<template>
  <v-card class="pb-5">
    <div class="head-modal py-6">
      <div></div>
      <div
        class="d-flex align-center"
        style="gap: 8px;"
      >
        <h3
          class="pl-5"
          style="font-weight: 500; font-size: 18px;"
        >
          Filtrar RIC's
        </h3>
      </div>
      <v-icon
        medium
        class="me-2 pr-4"
        @click="handleCloseModal"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>
    <div style="padding: 0px 20px;">
      <Separator label="Filtrar dados" />
      <form ref="form">
        <div
          class="d-flex flex-column mt-5"
        >
          <div
            class="d-flex"
            style="gap: 20px;"
          >
            <v-menu
              ref="menuStart"
              v-model="menuStart"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedStartDate"
                  label="Data inicial"
                  style="height: 62px"
                  dense
                  outlined
                  readonly
                  v-bind="attrs"
                  :append-icon="icons.mdiCalendar"
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                locale="pt-br"
                no-title
                scrollable
                @change="(date) => { $refs.menuStart.save(date); startDate = date; }"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menuStart = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuStart.save(startDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="menuEnd"
              v-model="menuEnd"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedEndDate"
                  label="Data final"
                  style="height: 62px"
                  dense
                  outlined
                  readonly
                  v-bind="attrs"
                  :append-icon="icons.mdiCalendar"
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                locale="pt-br"
                no-title
                scrollable
                @change="(date) => { $refs.menuEnd.save(date); endDate = date; }"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menuEnd = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuEnd.save(endDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <v-text-field
            v-model="protocol"
            outlined
            dense
            class="pb-0 mb-0"
            label="Protocolo"
          ></v-text-field>
          <div
            class="d-flex flex-column"
            style="gap: 25px;"
          >
            <v-autocomplete
              v-model="filial"
              :items="filialsList"
              small-chips
              clearable
              label="Loja"
              multiple
              dense
              outlined
              hide-details
            ></v-autocomplete>
            <v-autocomplete
              v-model="sector"
              class="mt-0 pt-0"
              :items="sectorsList"
              small-chips
              clearable
              multiple
              label="Setor"
              dense
              outlined
              hide-details
            ></v-autocomplete>
            <v-autocomplete
              v-model="gestor"
              :items="leadersList"
              small-chips
              clearable
              multiple
              label="Gestor"
              dense
              outlined
              hide-details
            ></v-autocomplete>
            <v-autocomplete
              v-model="status"
              :items="statusList"
              small-chips
              clearable
              multiple
              label="Status"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </div>
        </div>
        <div
          class="d-flex align-center justify-end mt-8"
          style="width: 100%; gap: 20px"
        >
          <v-btn
            v-if="hasFilters"
            outlined
            color="error"
            @click="clearModal"
          >
            <div
              class="d-flex align-end justify-center pb-1"
              style="gap: 10px;"
            >
              <v-icon size="22">
                {{ icons.mdiFilterVariantRemove }}
              </v-icon>
              <span style="font-weight: 600; font-size: 14px;">LIMPAR</span>
            </div>
          </v-btn>
          <v-btn
            color="info"
            @click="sendFilteredData"
          >
            <span style="font-weight: 600; font-size: 14px;">FILTRAR</span>
          </v-btn>
        </div>
      </form>
    </div>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosNewInstance from '@/plugins/newAxiosInstance'
import { mdiCalendar, mdiClose, mdiFilterVariantRemove } from '@mdi/js'
import moment from 'moment'

export default {
  name: 'ModalFilterRics',
  components: {
    Separator,
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiFilterVariantRemove,
        mdiCalendar,
      },
      startDate: null,
      endDate: null,
      sector: [],
      protocol: '',
      status: [],
      gestor: [],
      filial: [],

      menuStart: false,
      menuEnd: false,
      leadersList: [],
      sectorsList: [],
      statusList: [
        'AGUARDANDO CENTRAL',
        'AGUARDANDO LÍDER',
        'AGUARDANDO GR',
        'AGUARDANDO RD',
        'AGUARDANDO ENCERRAMENTO',
        'FINALIZADO',
      ],
      filialsList: [
        'MOTOCA AÇAILÂNDIA',
        'MOTOCA ITINGA',
        'MOTOCA IMPERATRIZ',
        'MOTOCA SENADOR',
        'MOTOCA GRAJAÚ',
        'MOTOCA BOM JESUS',
        'MOTOCA SITIO NOVO',
        'MOTOCA CIDELANDIA',
        'MOTOCA ARAME',
        'MOTOCA PORTO FRANCO',
        'MOTOCA AMARANTE',
        'MOTOCA FORMOSA',
        'CHAPARRAL',
        'FUJI MOTORS',
      ],
    }
  },
  computed: {
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
    },
    hasFilters() {
      return this.startDate || this.endDate || this.sector || this.status || this.gestor || this.filial || this.protocol
    },
  },
  created() {
    this.handleGetSectors()
    this.handleGetLeaders()
  },

  methods: {
    sendFilteredData() {
      const body = {
        initial_date: this.startDate,
        final_date: this.endDate,
        protocol: this.protocol,
        companies: this.filial,
        sectors: this.sector,
        managers: this.gestor,
        status: this.status,
      }

      this.$emit('isFiltered', this.isFiltered = true)
      this.$emit('filteredDataBody', body)
      this.$emit('getFilter')
      this.handleCloseModal()
    },
    clearModal() {
      this.startDate = null
      this.endDate = null
      this.sector = ''
      this.status = ''
      this.gestor = ''
      this.filial = ''
      this.protocol = ''
      this.$refs.form.reset()
      this.$emit('isFiltered', this.isFiltered = false)
      this.$emit('itemsTableIndex')
    },
    handleCloseModal() {
      this.$emit('close')
    },
    async handleGetSectors() {
      try {
        const response = await axiosNewInstance.get('/api/v1/records/sector/index')
        response.data.data.map(sectors => {
          this.sectorsList.push(sectors.name)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetLeaders() {
      try {
        const response = await axiosNewInstance.get('/api/v1/records/user/user_leader')
        response.data.data.map(leaders => {
          if (leaders.name === 'API') {
            return
          }
          this.leadersList.push(leaders.name)
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>

.head-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
