import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pb-2"},[_c('div',{staticClass:"container-title"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.closeModal()}}},[_c('span',{staticClass:"text-lg"},[_vm._v("X")])])],1),_c(VContainer,[_c('div',{staticClass:"container-divider"},[_c('div',{staticClass:"custom-divider-text"},[_c('h4',[_vm._v("Arquivos")])]),_c(VDivider)],1),_c(VFileInput,{ref:"fileInput",attrs:{"prepend-inner-icon":_vm.icons.mdiPaperclip,"prepend-icon":"","label":"Anexar arquivo","outlined":"","multiple":"","chips":"","dense":""},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('span',{staticClass:"container-caption"},[_vm._v(" * O tamanho do arquivo não pode exceder o limite máximo de 10MB. ")]),_c('div',{staticClass:"d-flex justify-center"},[_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.sendModalData()}}},[(!_vm.isLoading)?_c('div',{staticClass:"container-button"},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlusCircle)+" ")]),_c('span',{staticClass:"text-sm"},[_vm._v(" Inserir ")])],1):_c(VProgressCircular,{staticClass:"mx-6",attrs:{"color":"white","indeterminate":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }