<template class="pt-0">
  <v-card class="pb-2">
    <div class="container-title">
      <v-btn
        icon
        @click="closeModal()"
      >
        <span class="text-lg">X</span>
      </v-btn>
    </div>

    <v-container>
      <div
        class="container-divider"
      >
        <div class="custom-divider-text">
          <h4>Arquivos</h4>
        </div>
        <v-divider />
      </div>

      <v-file-input
        ref="fileInput"
        v-model="files"
        :prepend-inner-icon="icons.mdiPaperclip"
        prepend-icon=""
        label="Anexar arquivo"
        outlined
        multiple
        chips
        dense
      />

      <span
        class="container-caption"
      >
        * O tamanho do arquivo não pode exceder o limite máximo de 10MB.
      </span>

      <div class="d-flex justify-center">
        <v-btn
          color="success"
          @click="sendModalData()"
        >
          <div
            v-if="!isLoading"
            class="container-button"
          >
            <v-icon size="20">
              {{ icons.mdiPlusCircle }}
            </v-icon>
            <span class="text-sm">
              Inserir
            </span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            class="mx-6"
            indeterminate
          />
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiAlphaX, mdiPaperclip, mdiPlusCircle } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      files: [],
      isLoading: false,
      icons: { mdiAlphaX, mdiPaperclip, mdiPlusCircle },
    }
  },

  methods: {
    async sendModalData() {
      const { data } = this

      if (this.files.length > 0) {
        this.isLoading = true

        await this.sendFiles('api/v1/sgq/attachment/submit_files', this.files, { id: data?.id }).then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Arquivos enviados com sucesso!',
          })
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.files = []
          this.isLoading = false
          this.updateImageList()
          this.closeModal()
        })
      }
    },

    updateImageList() {
      this.$emit('getImageList')
    },
  },
}
</script>

<style scoped>
.container-title {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
}

.container-button {
  display: flex;
  justify-content:center;
  align-items: center;
  gap: 5px;
}

.container-divider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

.custom-divider-text {
  display: relative;
  width: 20%;
  text-align: center;
  background: #312D4B;
  font-size: 14px;
  margin-left: 40px;
  margin-bottom: -12px;
  z-index: 1;
}

.container-caption {
  display: flex;
  margin-top: -20px ;
  margin-bottom: 20px;
  font-size: 12px ;
  padding-left: 4px;

}
</style>
